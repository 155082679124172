:root {
  --primary_color: #005556;
  --bg_color: #1b0029;
  --white_color: #fff;
}

/* sidebar css scroll thumb  */
.w3-sidebar {
  width: 250px;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.w3-sidebar::-webkit-scrollbar {
  width: 5px;
}

.w3-sidebar::-webkit-scrollbar-track {
  background: #1b0029;
  border-radius: 10px;
}

.w3-sidebar::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 10px;
}

.w3-sidebar::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

.w3-sidebar::-webkit-scrollbar-horizontal {
  height: 12px;
}

.w3-sidebar::-webkit-scrollbar-track-horizontal {
  background: #1b0029;
}

.w3-sidebar::-webkit-scrollbar-thumb-horizontal {
  background: #888;
  border-radius: 10px;
}

.w3-sidebar::-webkit-scrollbar-thumb-horizontal:hover {
  background: #555;
}

/* sidebar css scroll thumb  */
 /* home page css  */
 .width_40{
  width:40%
 }
 .width_20{
  width: 20%;
 }
 .width_20 img{
  margin: auto;
 }
 /* home page css  */

/* profile name  */
.profile_name_design{
  font-size: 25px;
}
/* profile name  */
/* hourglass  */
.loadernew{
  position: absolute;
  left: 15px;
  top: 62px;
}
.loadernew.right{
  right: 15px;
  left: auto;
  top: 62px;
}
.loadernew span {
  font-size: 45px;
  animation: spinnew 2s linear infinite;
  display: inline-block;
}

@keyframes spinnew {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.dice-container.right.pending_wait{
  right: 0px;
  perspective: 1000px;
  position: absolute;
  bottom: -30px;
  top: 0px;
}
/* hourglass  */
/* new css 12/18/2024 */

.winnersize{
  width:100%;
  height:130px;
  background-size: 400% 400%;
  animation: gradientBG 10s infinite alternate;
  backdrop-filter: blur(10px);
  overflow:hidden;
  position: relative;
  z-index: 1;
}

@keyframes gradientBG {
  0% { background-position: 0% 50%; }
  50% { background-position: 50% 100%; }
  100% { background-position: 100% 50%; }
}
/* Winner Announcement Box */
.winner-container {
  border-radius: 15px;
  animation: fadeIn 2s ease-out;
  width: 100%;
  background: url('../../../public/Images/Homepage/gameover.png') center/cover no-repeat,
  linear-gradient(135deg, #ff3333, #ff6666);
  background-size: cover;
  height: 130px;
  background-repeat: no-repeat;
  display: flex;
      align-items: center;
      justify-content: center;
}
.winner-header {
  font-size: 22px;
  font-weight: bold;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  margin-bottom: 0px;
  color: #ffff;
}

.winner-name {
  font-size: 20px;
  font-weight: bold;
  color: #ffeb3b;
  margin: 0px 0;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.winner-image {
  margin-top: 10px;
  width: 75px;
  height: 75px;        
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  animation: pulse 2s infinite alternate;
}

@keyframes pulse {
  from { transform: scale(1); box-shadow: 0 0 10px #ffeb3b; }
  to { transform: scale(1.1); box-shadow: 0 0 20px #ffcc70; }
}

.winner-message {
  font-size: 13px;
  color: #fffd82;
  font-style: italic;
  text-align: center;
  margin: auto;
  width: 60%;
}

/* Glitter Animation */
.glitter {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: gold;
  opacity: 0.7;
  border-radius: 50%;
  animation: glitterFall 4s linear infinite;
}

@keyframes glitterFall {
  from { transform: translateY(-100px) scale(1); opacity: 1; }
  to { transform: translateY(100vh) scale(0.5); opacity: 0; }
}

/* Button Styles */
.action-button {
  margin-top: 20px;
  background: #ff9800;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 1.2rem;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
}

.action-button:hover {
  transform: scale(1.1);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

/* Fade-in Effect */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}
.content_design{
  display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
}


  .blast-container {
      position: absolute;
      width: 20px;
      height: 20px;
      right: 0px;
  }

    .blast {
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #FFD700;
      opacity: 0.8;
      bottom:0px;
      animation: blastAnimation 1.5s forwards;
    }

    @keyframes blastAnimation {
      0% {
        transform: scale(1) translate(0, 0);
        opacity: 1;
      }
      100% {
        transform: scale(0.5) translate(var(--x), var(--y));
        opacity: 0;
      }
    }
    .winneremoji {
      position: absolute;
      width: 60px;
      height: 59px;
      right: 19px;
      bottom: 0px;
  }
    .winneremoji.left {
      position: absolute;
      width: 60px;
      height: 59px;
      left: 25px;
      bottom: 0px;
      right: auto;
      transform: scaleX(-1);
  }
    .winneremoji img{
      width:100%;
      height:100%;
    }
    .Congratulations{
      position:relative;
      width: 250px;
      height: auto;
      z-index: 1;
    }
    .imgCongratulations{
      position:absolute;
      
    }
    .imgCongratulations img{
      width:100%;
      height:100%;
    }
    .cheers {
      width: 70px;
      height: auto;
      position: absolute;
      right: 0px;
  }
  .cheers.left{
      right: auto;
      left:0px;
  }
    .cheers img{
      width:100%;
      height:100%
    }
    
.linear-wipe {
text-align: center;

background: linear-gradient(to right, #FFF 20%, #FF0 40%, #FF0 60%, #FFF 80%);
background-size: 200% auto;

color: #000;
background-clip: text;
text-fill-color: transparent;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;

animation: shine 1s linear infinite;
}
@keyframes shine {
to {
background-position: 200% center;
}
}


/* sad emoji */
.sadgiff{
position: absolute;
left:0px;
width:75px;
bottom: 16px;
}
.sadgiff img{
width:100%;
height: 100%;
transform: scale(2);
}

.sadgiff.left{
position: absolute;
left:auto;
right:0px;
width:75px;

}
.badluck{
position: relative;
z-index: 9;
}
.dice-container.losethematch {
  perspective: 1000px;
  position: absolute;
  top: 0px;
  left: -48px;
}
.dice-container.losethematch.right {
  right: -48px;
  perspective: 1000px;
  position: absolute;
  top: 0px;
  left: auto;
}
.icon-container {
position: relative;
}

.main-icon {
font-size: 30px;
color: #d32f2f;
animation: grow 2s infinite;
position: absolute;
top: 51px;
left: 86%;
}

.watermark {
position: absolute;
font-size: 23px;
color: rgb(0 0 0 / 51%);
opacity: 0;
animation: dropWatermark 3s infinite;
}
.main-icon.left{
left:0px;
animation: growleft 2s infinite;
right: auto;
left: 34px;
right: auto;
transform: scaleX(-1) rotate(355deg);
top: 51px;

}

@keyframes growleft {
0%, 100% {
transform: translate(-50%, -50%) scale(1) scaleX(-1) rotate(355deg);
}
50% {
transform: translate(-50%, -50%) scale(1.2) scaleX(-1) rotate(355deg);
}
}
@keyframes grow {
0%, 100% {
transform: translate(-50%, -50%) scale(1) rotate(355deg);
}
50% {
transform: translate(-50%, -50%) scale(1.2) rotate(355deg);
}
}

@keyframes dropWatermark {
0% {
opacity: 0.4;
transform: translateY(-30px) scale(0.8);
}
50% {
opacity: 1;
transform: translateY(30px) scale(1);
}
100% {
opacity: 0.8;
transform: translateY(60px) scale(0.5);
}
}

.winnersize{
  width:100%;
  height:130px;
  background-size: 400% 400%;
  animation: gradientBG 10s infinite alternate;
  backdrop-filter: blur(10px);
  overflow:hidden;
  position: relative;
  z-index: 1;
}
/* .winnersize::before{
  content: "";
  position: absolute;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 78%);
  z-index: 0;
  border-radius: 15px;
} */
@keyframes gradientBG {
  0% { background-position: 0% 50%; }
  50% { background-position: 50% 100%; }
  100% { background-position: 100% 50%; }
}
/* Winner Announcement Box */
.winner-container {
  border-radius: 15px;
  animation: fadeIn 2s ease-out;
  width: 100%;
  background: url('../../../public/Images/Homepage/gameover.png') center/cover no-repeat,
  linear-gradient(135deg, #ff3333, #ff6666);
  background-size: cover;
  height: 130px;
  background-repeat: no-repeat;
  display: flex;
      align-items: center;
      justify-content: center;
}
.winner-header {
  font-size: 22px;
  font-weight: bold;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  margin-bottom: 0px;
  color: #ffff;
}

.winner-name {
  font-size: 20px;
  font-weight: bold;
  color: #ffeb3b;
  margin: 0px 0;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.winner-image {
  margin-top: 10px;
  width: 75px;
  height: 75px;        
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  animation: pulse 2s infinite alternate;
}

@keyframes pulse {
  from { transform: scale(1); box-shadow: 0 0 10px #ffeb3b; }
  to { transform: scale(1.1); box-shadow: 0 0 20px #ffcc70; }
}

.winner-message {
  font-size: 13px;
  color: #fffd82;
  font-style: italic;
  text-align: center;
  margin: auto;
  width: 60%;
}

/* Glitter Animation */
.glitter {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: gold;
  opacity: 0.7;
  border-radius: 50%;
  animation: glitterFall 4s linear infinite;
}

@keyframes glitterFall {
  from { transform: translateY(-100px) scale(1); opacity: 1; }
  to { transform: translateY(100vh) scale(0.5); opacity: 0; }
}

/* Button Styles */
.action-button {
  margin-top: 20px;
  background: #ff9800;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 1.2rem;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
}

.action-button:hover {
  transform: scale(1.1);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

/* Fade-in Effect */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}
.content_design{
  display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
}


/* new css 12/18/2024 */

.winnersize{
  width:100%;
  height:150px;
  background-size: 400% 400%;
  animation: gradientBG 10s infinite alternate;
  backdrop-filter: blur(10px);
  overflow:hidden;
}

@keyframes gradientBG {
  0% { background-position: 0% 50%; }
  50% { background-position: 50% 100%; }
  100% { background-position: 100% 50%; }
}
/* Winner Announcement Box */
.winner-container {
  /* border-radius: 15px;
  animation: fadeIn 2s ease-out;
  width: 100%;
  background-image: url(../../../public/Images/banner/ludo_bg.png);
  background-size: cover;
  height: 130px;
  background-repeat: no-repeat;
  display: flex;
      align-items: center;
      justify-content: center; */
      border-radius: 15px;
      animation: fadeIn 2s ease-out;
      width: 100%;
      background: url('../../../public/Images/Homepage/gameover.png') center/cover no-repeat
      ;
      background-size: cover;
      height: 150px;
      background-repeat: no-repeat;
      display: flex;
          align-items: center;
          justify-content: center;
    }
.winner-header {
  font-size: 22px;
  font-weight: bold;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  margin-bottom: 0px;
  color: #ffff;
}

.winner-name {
  font-size: 20px;
  font-weight: bold;
  color: #ffeb3b;
  margin: 0px 0;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.winner-image {
  margin-top: 10px;
  width: 75px;
  height: 75px;        
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  animation: pulse 2s infinite alternate;
}

@keyframes pulse {
  from { transform: scale(1); box-shadow: 0 0 10px #ffeb3b; }
  to { transform: scale(1.1); box-shadow: 0 0 20px #ffcc70; }
}

.winner-message {
  font-size: 13px;
  color: #fffd82;
  font-style: italic;
  text-align: center;
  margin: auto;
  width: 60%;
}

/* Glitter Animation */
.glitter {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: gold;
  opacity: 0.7;
  border-radius: 50%;
  animation: glitterFall 4s linear infinite;
}

@keyframes glitterFall {
  from { transform: translateY(-100px) scale(1); opacity: 1; }
  to { transform: translateY(100vh) scale(0.5); opacity: 0; }
}

/* Button Styles */
.action-button {
  margin-top: 20px;
  background: #ff9800;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 1.2rem;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
}

.action-button:hover {
  transform: scale(1.1);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

/* Fade-in Effect */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}
.content_design{
  display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
    top: -21px;
}


/* dice css  */
/* Dice Container with Perspective */
.dice-container {
  left: -10px;
  perspective: 1000px;
  position: absolute;
  top: 5px;
}

div.dice-container.right {
  right: 50%;
  perspective: 1000px;
  position: absolute;
  bottom: -30px;
  left: auto;
  top: auto;
}

/* 3D Dice Styling */
.dice {
  width: 20px;
  height: 20px;
  position: relative;
  transform-style: preserve-3d;
  transform: rotateX(30deg) rotateY(45deg);
  animation: rotateDice 3s infinite linear;
}

/* Dice Faces */
.dice-face {
  position: absolute;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 8px;
  font-weight: bold;
  border: 1px solid #333;
  border-radius: 5px;
  background-color: #ff0000;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
}

/* Face positioning */
.face1 { transform: rotateY(  0deg) translateZ(10px); }
.face2 { transform: rotateY( 90deg) translateZ(10px); }
.face3 { transform: rotateY(180deg) translateZ(10px); }
.face4 { transform: rotateY(-90deg) translateZ(10px); }
.face5 { transform: rotateX( 90deg) translateZ(10px); }
.face6 { transform: rotateX(-90deg) translateZ(10px); }

/* Dotted Design for Dice Faces */
.dot {
  position: absolute;
  width: 2px;
  height: 2px;
  background-color: #ffeb3b; /* Yellow color for dots */
  border-radius: 50%;
  box-shadow: 0 0 2px rgba(255, 223, 0, 0.8);
}

.face1 .dot1 {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.face2 .dot1 {
  top: 20%;
  left: 20%;
}
.face2 .dot2 {
  bottom: 20%;
  right: 20%;
}

.face3 .dot1 {
  top: 20%;
  left: 20%;
}
.face3 .dot2 {
  top: 20%;
  right: 20%;
}
.face3 .dot3 {
  bottom: 20%;
  left: 20%;
}

.face4 .dot1 {
  top: 20%;
  left: 20%;
}
.face4 .dot2 {
  top: 20%;
  right: 20%;
}
.face4 .dot3 {
  bottom: 20%;
  left: 20%;
}
.face4 .dot4 {
  bottom: 20%;
  right: 20%;
}

.face5 .dot1 {
  top: 20%;
  left: 20%;
}
.face5 .dot2 {
  top: 20%;
  right: 20%;
}
.face5 .dot3 {
  bottom: 20%;
  left: 20%;
}
.face5 .dot4 {
  bottom: 20%;
  right: 20%;
}
.face5 .dot5 {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.face6 .dot1 {
  top: 20%;
  left: 20%;
}
.face6 .dot2 {
  top: 20%;
  right: 20%;
}
.face6 .dot3 {
  bottom: 20%;
  left: 20%;
}
.face6 .dot4 {
  bottom: 20%;
  right: 20%;
}
.face6 .dot5 {
  top: 50%;
  left: 20%;
}
.face6 .dot6 {
  top: 50%;
  right: 20%;
}

/* Animation for rotating dice */
@keyframes rotateDice {
  0% { transform: rotateX(0deg) rotateY(0deg); }
  50% { transform: rotateX(180deg) rotateY(180deg); }
  100% { transform: rotateX(360deg) rotateY(360deg); }
}

/* dice css  */
  .blast-container {
      position: absolute;
      width: 20px;
      height: 20px;
      right: 0px;
  }

    .blast {
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #FFD700;
      opacity: 0.8;
      bottom:0px;
      animation: blastAnimation 1.5s forwards;
    }

    @keyframes blastAnimation {
      0% {
        transform: scale(1) translate(0, 0);
        opacity: 1;
      }
      100% {
        transform: scale(0.5) translate(var(--x), var(--y));
        opacity: 0;
      }
    }
    .winneremoji {
      position: absolute;
      width: 60px;
      height: 59px;
      right: 40px;
      bottom: 0px;
  }
    .winneremoji.left {
      position: absolute;
      width: 60px;
      height: 59px;
      left: 40px;
      bottom: 0px;
      right:auto;
  }
    .winneremoji img{
      width:100%;
      height:100%;
    }
    .Congratulations{
      position:relative;
      width: 250px;
      height: auto;
      z-index: 1;
    }
    .imgCongratulations{
      position:absolute;
      
    }
    .imgCongratulations img{
      width:100%;
      height:100%;
    }
    .cheers {
      width: 89px;
    height: auto;
    position: absolute;
    right: 0px;
    top: -19px;

  }
  .cheers.left{
      right: auto;
      left:0px;
  }
    .cheers img{
      width:100%;
      height:100%
    }
    
.linear-wipe {
text-align: center;

background: linear-gradient(to right, #FFF 20%, #FF0 40%, #FF0 60%, #FFF 80%);
background-size: 200% auto;

color: #000;
background-clip: text;
text-fill-color: transparent;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;

animation: shine 1s linear infinite;
}
@keyframes shine {
to {
background-position: 200% center;
}
}



p {
  font-family: sans-serif !important;
}

.placeholdercolorchange::placeholder {
  color: #fff;
}

.paddingallpage {
  padding: 65px 10px;
}

.loseresult {
  position: relative;
}

.loseresult::before {
  content: "";

}

.bethistorydesign {
  background: #4a1b63;
  border: 1px solid #dd9eff;
  border-radius: 12px;
}
.bgimage_new {
  background: url('../../assets/images/bgreank.png');
  width: 43px;
  height: 42px;
  white-space: nowrap;
  background-size: contain;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 29px;
  background-repeat: no-repeat;
}

.inputdesigncolor input::placeholder,
.inputdesigncolor input {
  color: #fff;
}

.padding_15 {
  padding: 0px 15px;
}

.margin-top-all {
  margin-top: 10px;
}

.contentboard {
  padding: 0px 15px;
  font-weight: 500;
  color: #531771;
  text-transform: uppercase;
}

.bgimage_new.prize {
  font-weight: 500;
}

.pricerank {
  border-bottom: 1px solid #e1e1e1;
  padding: 7px 0px;
}

.gap-2 {
  gap: 15px;
}

body {
  color: #fff;
}

.bg-1 {
  background: var(--primary_color);
}

.headerContainer,
.leftContainer {
  background-color: var(--bg_color);
  max-width: 480px;
  width: 100%;
  color: var(--white_color);
}

* {
  font-family: "Roboto";
}

*,
:after,
:before {
  box-sizing: border-box;
}

.leftContainer {
  min-height: 0%;
  position: relative;
}

.addcash_btn {
  background: #460666;
  border-radius: 12px;
  border: 1px solid #cb62ff;
}

.right_bar {
  position: absolute;
  bottom: 0;
}

.list-group-item {
  color: #9161a9;
}

.addcash_input {
  color: var(--white_color);
}

.input_cash {
  background: #700ca0;
  border-radius: 12px;
  padding: 5px 10px;
}

.input_cash p {
  font-size: 18px;
}

.min_max {
  margin: 10px 0 2px !important;
}

.input_cash .form-control:focus,
.input_cash input {
  background: transparent;
  border: none;
  color: #fff;
}

.time_date {
  font-size: 11px;
  margin-bottom: 0px !important;
}

.headerContainer {
  position: fixed;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 6%);
  height: 0px;
  padding: 0;
  top: 0;
  z-index: 2;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.navLogo>img {
  height: 36px;
  width: 36px;
}

.login-btn {
  border: 1px solid #fff;
  color: #fff !important;
  border-radius: 20px;
  font-size: 1.5em;
  font-weight: 700;
  height: 44px;
  padding: 4px 21px 20px;
  margin-right: 10px;
  background: linear-gradient(45deg, #c221e9, #8506f6);
}

.rightContainer {
  background: url(../../../public/Images/global-bg-rays.jpg);
  background: linear-gradient(90deg, #59107d, #230b83 47%, #570e7c);
  background-color: #fff;
  background-size: cover;
  overflow: hidden;
  right: 0;
  z-index: 4;
  color: #fff;
}

.rightContainer {
  bottom: 0;
  left: 480px;
  position: fixed;
  top: 0;
  border-left: 5px solid #e0e0e0;
}

.open_battle {
  padding: 0;
}

.wallet_cash {
  background: #4CAF50;
  padding: 4px 10px;
  outline: 1px solid #ffffff;
  outline-offset: -5px;
}

.add_btn {
  border-left: 1px solid #e5e5e5;
  margin-left: 8px;
  padding: 4px 8px;
}

.wallet_cash .fa-wallet {
  font-size: 22px;
}

.wallet_cash .fa-rupee {
  font-size: 18px;
}

.footer_menu {
  background: #311142;
  bottom: 0;
  color: #fff;
  max-width: 480px;
  padding: 8px 15px;
  position: fixed;
  width: 100%;
  text-align: center;
  box-shadow: 0px 0px 5px #704bd1;
}

.footer_menu a {
  color: #fff !important;
}

.footer_menu a span {
  display: block;
  font-size: 12px;
}

.log_out_btn {
  padding-bottom: 80px;
}

.manual_pay {
  color: var(--white_color);
  height: 90vh;
}

.bid .open_battle:first-child {
  padding-top: 10px;
  padding-bottom: 2px;
  border-radius: 8px 8px 0 0;
}

.bid .open_battle:last-child {
  padding-top: 2px;
  padding-bottom: 15px;
  border-radius: 0 0 8px 8px;
  margin-bottom: 10px;
}

.rcBanner-footer>img {
  height: 25px;
  width: 25px;
  margin-top: 4px;
}

.rcBanner-img-container {
  bottom: 45%;
  position: absolute;
  width: 300px;
}

.rcBanner-img-container>img {
  height: auto;
  width: 220px;
}

.flex-center {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rcBanner-text {
  bottom: 35%;
  font-family: "Roboto Condensed";
  font-size: 2em;
  font-weight: 400;
  position: absolute;
  text-transform: uppercase;
}

.rcBanner-text-bold {
  font-weight: 900;
}

.main-area {
  padding-top: 10px;
  background-color: transparent;
  overflow: hidden;
  margin-top: 27px;
  width: 100%;
}

.setamt {
  border-radius: 5px;
}

.games-section {
  background: #311142;
  border-radius: 0;
  position: relative;
  width: 100%;
}

.p-3 {
  padding: 1rem !important;
}

.games-section-headline {
  color: #959595;
  font-size: 0.75em;
  font-weight: 400;
  line-height: 18px;
  background-color: transparent;
  position: relative;
  border-radius: 20px;
}

.popins {
  font-family: "Poppins", sans-serif;
}

.games-section-headline:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  opacity: 0.3;
  background: url(../../../public/Images/Homepage/dice.png);
  background-position: center;
  background-size: contain;
}

.Inner_profile {
  position: relative;
}

.games-window {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 80px;
}

.gameCard-container {
  margin-bottom: 20px;
  justify-content: center;
  position: relative;
  width: 46.8%;
}

.bg_new {
  background: #f39e09;
}

.blink {
  animation: animate 2s linear infinite;
}

@keyframes animate {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.gameCard {
  background-color: #fff;
  border-radius: 5px;
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none;
}

.gameCard-image {
  border-radius: 5px;
  height: 70.33%;
  width: 100%;
}

.gameCard-image img {
  border-radius: 5px;
  filter: drop-shadow(2px 4px 6px black);
}

.gameCard-icon,
.gameCard-title {
  display: flex;
  justify-content: center;
}

.gameCard-title {
  border-radius: 0 0 5px 5px;
  border: 1px solid #e0e0e0;
  border-top: none;
  color: #2c2c2c;
  font-weight: 700;
  padding: 15px 20px 10px;
}

.games-section-title {
  font-size: 1em;
  font-weight: 100;
}

.rcBanner-footer {
  bottom: 40px;
  color: #ccc;
  font-size: 1.5em;
  font-weight: 400;
  line-height: 32px;
  position: absolute;
  text-align: center;
}

.footer {
  /* background-image: linear-gradient(rgb(61, 128, 197), rgb(37, 61, 118)) !important; */
  border-radius: 0 !important;
  position: relative !important;
  width: 100% !important;
  text-decoration: black;
  padding: 1% !important;
}

.footer-2 {
  background-image: linear-gradient(to right, #74e933, #e9d629);
}

.footer-divider {
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.footer-links>a {
  color: #050505 !important;
  display: flex !important;
  font-size: 1.3em !important;
  font-weight: 400 !important;
  margin-bottom: 10px !important;
}

.footer-text-bold {
  color: #03d25e;
  font-size: 1em;
  font-weight: 700;
}

.footer-text {
  color: #050505;
  font-size: 0.9em;
  font-weight: 400;
}

a:hover {
  color: #0056b3 !important;
  text-decoration: underline;
}

a {
  color: #007bff !important;
  text-decoration: none;
  background-color: transparent;
}

.gameCard-container .goverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #008cba;
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.collapseCard-container {
  padding: 30px 20px 20px;
  background-color: var(--primary_color);
}

.collapseCard {
  position: relative;
  width: 100%;
}

.collapseCard-body {
  padding-left: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  align-items: center;
  display: flex;
}

.collapseCard-header {
  background-color: #fafafa;
  padding-left: 3px;
  padding-right: 3px;
  top: -13px;
  align-items: center;
  position: absolute;
  display: flex;
  justify-content: center;
}

.collapseCard-title {
  color: #676767;
  font-size: 0.7em;
  font-weight: 700;
  text-transform: uppercase;
}

.alert-box {
  color: #21940a;
  background-color: #080006;
  border-color: #b8b265;
  border-radius: 0;
  padding: 9px 7px 2px 7px;
}

.header_top_message {
  background-color: #6945c2;
  color: #fff !important;
  font-family: Poppins, sans-serif !important;
  font-size: 12px;
  border-top: 1px solid #929292cc;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 17px;
  max-width: 480px;
  padding: 8px;
  text-align: center;
  top: 10;
  width: 480px;
  width: 100%;
  z-index: 2;
}

.w1 {
  display: flex;
  justify-content: center;
  margin-bottom: 3px;
  position: relative;
}

.w2 {
  background-color: #03d25e;
  color: #fff;
  padding: 10px;
  border-radius: 30px;
  cursor: pointer;
  animation: 0s ease 0s infinite normal none running flash;
}

.w3 {
  font-weight: bold;
}

.whatsapp {
  padding: 15px;
  font-size: 35px;
  border-radius: 50%;
  display: inline-flex;
  position: absolute;
  top: -10px;
  box-shadow: inset 0 0 8px #000c02;
}
.whatsap_btn a{
  animation-name: whatsapp;
	animation-duration: 1.5s;
	animation-timing-function: ease-out;
	animation-iteration-count: infinite;
  border-radius: 50%;
}
@keyframes whatsapp {
	0% {
		box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.5);
	}
	80% {
		box-shadow: 0 0 0 14px rgba(37, 211, 102, 0);
	}
}

.support_samll_card_btn_type {
  align-items: center;
  /* background-color: #f6f8fa;
  border: 1px solid #c8c8c8 !important; */
  border-radius: 10px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100px;
  padding: 0.2rem 0.85rem !important;
}

.whatsap_btn,
.whatsap_btn1 {
  bottom: 69px;
  min-height: auto !important;
  position: fixed;
  z-index: 9999999999999;
}

.whatsap_btn {
  right: 20px;
}

.cash_btn {
  background: linear-gradient(45deg, #7951e5, #3bb7af);
  color: var(--white_color);
}

.bg_color {
  background: #560c7b !important;
}

.Addcase_games_section_title__-FZq- {
  font-size: 1.2rem !important;
}

.wallet_page .card {
  border-radius: 12px;
  padding: 15px;
  color: #fff;
}

.all_policy li {
  padding: 10px;
  margin-top: 10px;
  background: #ebedf0;
  font-family: sans-serif;
  color: #010101;
  border-radius: 8px;
  border: 1px solid #ddd;
}

.next_btn {
  background: linear-gradient(45deg, #7951e5, #3bb7af);
}

.privacy p {
  font-family: sans-serif;
}

.wallet_page {
  height: 80vh;
}

span.challanger {
  color: #d65317;
  font-weight: 700;
  font-size: 12px;
}

.refer_box {
  background: #560c7b;
  border: 1px solid #c8c8c8;
  border-radius: 8px;
  width: 100%;
}

.refer_box .earn {
  border-bottom: 1px solid;
}

.referbtn_refer {
  border-radius: 12px !important;
}

.refer_footer .back_btn .btn {
  border-radius: 12px !important;
}

.refer_code {
  border-radius: 5px 0 0 5px;
}

button.cash_btn {
  border-radius: 0 5px 5px 0;
}

.rcBanner-footer a.right_app span {
  color: #ffd900;
  font-weight: 500;
  text-decoration: none;
}

.match_status {
  position: absolute;
  width: 100%;
  color: #4f0f7e;
  background: #fff;
  bottom: 65px;
  left: 10px;
  width: 95%;
  border-radius: 10px;
}

.running_bettle {
  margin-bottom: 60px;
}


input.text-white.Profile_MuiInputBase_input__yci-A::placeholder {
  color: #fff;
}

.winner_tab {
  background: #fff;
  width: 100%;
  display: flex;
  border-bottom: 1px solid #a4a4a4;
  box-shadow: 0 5px 10px 0 #7b7b7b4b;
  justify-content: space-around;
}

.winner_tab button {
  border: none;
  background: transparent;
  padding: 10px;
  width: 100%;
  font-weight: 700;
  border-bottom: 2px solid transparent;
}

.leaderboardesign {
  padding: 0px 15px;
}

.prize {
  font-weight: 700;
}

.leaderboard {
  color: #000;
}

.unstyled {
  list-style: none;
}

.unstyled li {
  font-weight: 600;
  padding: 5px;
  font-size: 13px;
}

.creator_table td {
  font-weight: 600;
}

/* pagination  css*/

.pagination-controls {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination-controls button {
  margin: 0 5px;
  /* padding: 5px 10px; */
  cursor: pointer;
}


.pagination-controls button.active {
  font-weight: bold;
  background-color: #b18fc2;
  color: white;
  border-radius: 2px;
  border: none;
}

.pagination-controls button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}


.prize-pool-content {
  border-radius: 10px;
  box-shadow: 0 2px 5px 0 #00000040;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  color: #000;
}

.prize-pool-content .title .prize_text {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.prize-pool-content .title .amount {
  font-size: 24px;
}

.prize-pool-content .tournament-entries .heading {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.prize-pool-content .tournament-entries .progress-bar {
  background-color: #d9d9d9;
  border-radius: 5px;
  height: 10px;
  margin: 5px 0;
  overflow: hidden;
  width: 100%;
}

.prize-pool-content .tournament-entries .progress-bar-fill {
  background-color: green;
  border-radius: 5px;
  height: 100%;
  transition: width .3s ease-in-out;
}

.prize-pool-content .tournament-entries .fill-amount {
  font-size: 14px;
  font-weight: 400;
  font-family: Poppins, sans-serif;
  ;
}

.prize-pool-content .tournament-statistics {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 5px 0 0;
  padding: 0;
}

.tournament-statistics {
  padding: 0;
}

.tournament-statistics li i {
  font-size: 25px;
}

.prize_title img {
  position: absolute;
  right: 0;
  width: 100%;
  opacity: 0.1;
}

.distribution {
  box-shadow: 0 2px 5px 0 #00000040;
  border-radius: 10px;
  border: none;
  position: relative;
}

.distribution:before {
  content: "";
  background: url(../../../public/Images/trofy.png);
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.1;
}

.prize_title {
  color: #000;
  font-size: 24px;
  font-weight: 700;
  margin: 10px;
  text-align: center;
  border-bottom: 1px solid #ccc;
}

.prize-pool-content .tournament-statistics li {
  align-items: center;
  display: flex;
  gap: 10px;
  list-style: none;
}

.prize-pool-content .tournament-statistics li img {
  height: 30px;
  width: 30px;
}

.prize-pool-content .tournament-statistics li .title {
  font-size: 14px;
  text-transform: uppercase;
}

.prize-pool-content .tournament-statistics li .amount {
  font-size: 18px;
  font-weight: 600;
  font-family: Poppins, sans-serif;
}

.detail {
  color: #000;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;

  p {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.fa-heart {
  padding: 5px;
  background: rebeccapurple;
  border-radius: 50%;
  color: #fff;
  margin-right: 5px;
  font-size: 10px;
}


.entry {
  line-height: 1.5;
}


.fixclassprize {
  align-items: center;
  background-color: #fff;
  bottom: 60px;
  box-shadow: 0 -5px 10px 0 #7b7b7b4b;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  left: 20px;
  max-width: 440px;
  padding: 20px 10px;
  position: fixed;
  width: 90%;
  border-radius: 8px;
}

.joiner_table table th {
  background-color: #a077b5;
  box-shadow: inset 0 0 0 9999px #9d72b2;
  padding: .5rem;
  color: #fff;
  border: none !important;
}


.game-rule-faq {
  border-radius: 10px;
  box-shadow: 0 2px 5px 0 #00000040;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
  padding: 15px;
  position: relative;
  color: #000;
}

.game-rule-faq .title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 5px;
  color: #010101;
}

.game-rule-faq .faq-section .accordion .accordion-item .accordion-header button {
  background-color: initial !important;
  border: none;
  box-shadow: none;
  color: #000 !important;
  font-size: 14px !important;
  font-weight: 600;
  padding: 12px 0;
  text-align: left;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.game-rule-faq .faq-section .accordion .accordion-item .accordion-header button.accordion-button:after {
  background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
  background: initial;
  color: #000;
  content: "+";
  font-size: 22px;
  font-weight: 500;
  height: 20px;
  line-height: 1;
  width: auto;
}

.game-rule-faq .bold {
  font-weight: 600;
}

.amount_result {
  font-size: 10px;
}

.amount_result span {
  display: block;
  margin-left: 5px;
}

.msgsign {
  background-color: red;
  color: #fff;
}

.checkroombtn {
  text-align: center;
}

.checkroombtn button {
  color: #fff;
}

/* Container for the Ludo Lose Banner */
.ludo-lose-banner {
  width: 100%;
  height: 180px;
  background: url('../../../public/Images/Homepage/gameover.png') center/cover no-repeat,
    linear-gradient(135deg, #ff3333, #ff6666);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: 'Comic Sans MS', cursive, sans-serif;
  border-radius: 15px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  animation: backgroundMove 5s linear infinite alternate;
}

/* Animated Background Effect */
@keyframes backgroundMove {
  0% {
    background-position: left bottom;
  }

  100% {
    background-position: right top;
  }
}

/* Content Container */
.lose-animation-content {
  text-align: center;
  z-index: 2;
  animation: fadeIn 2s ease-in-out;
}

/* Main Lose Message */
.lose-message {
  font-size: 1.8rem;
  font-weight: bold;
  margin: 5px 0;
  animation: bounce 1.5s infinite;
}

/* Rotating Dice Emojis */
.dice-animation {
  font-size: 1.5rem;
  margin: 10px 0;
  display: flex;
  justify-content: center;
  gap: 15px;
  animation: diceRotate 5s linear infinite;
}

/* Retry Message */
.retry-message {
  font-size: 1rem;
  font-style: italic;
  margin-top: 5px;
  animation: fadeInUp 1.5s ease-in-out;
}

/* Bounce Animation for Text */
@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-10px);
  }

  60% {
    transform: translateY(-5px);
  }
}

/* Dice Rotation Animation */
@keyframes diceRotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Fade-In Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Fade-In-Up Animation */
@keyframes fadeInUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}


.cheerWinner {
  position: absolute;
  width: 100%;
  height: auto;
  opacity: 0.5;
  left: 0;
  z-index: -1;
  top: 0;
}

.champion {
  font-size: 12px;
  font-weight: 700;
  text-align: center;
}

.champion span {
  display: block;
  font-size: 36px;
  color: #101a34 !important;
  font-weight: 700;
  background: linear-gradient(to right, #ff058e 10%, #ffbc00 50%, #fd5300 60%);
  background-size: 200% auto;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 1.5s linear infinite;
}

@keyframes textclip {
  100% {
    background-position: 200% center;
  }
}



.glitter, .star-glitter {
  position: absolute;
  width: 10px;
  height: 10px;
  background: gold;
  border-radius: 50%;
  animation: glitter-fall linear infinite;
}

@keyframes glitter-fall {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(100vh);
    opacity: 0;
  }
}

.blast {
  position: absolute;
  width: 10px;
  height: 10px;
  background: red;
  border-radius: 50%;
  transform: translate(var(--x), var(--y));
  animation: blast-move 1.5s ease-out;
}

@keyframes blast-move {
  to {
    transform: translate(var(--x), var(--y));
    opacity: 0;
  }
}

/* .dice {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
} */

/* .dice-face {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  width: 100px;
  height: 100px;
  background: #fff;
  border: 1px solid #ddd;
  margin: 5px;
  position: relative;
} */

/* .dot {
  width: 20px;
  height: 20px;
  background: black;
  border-radius: 50%;
} */

/* .dot1 { grid-area: 2 / 2; }
.dot2 { grid-area: 1 / 1; }
.dot3 { grid-area: 1 / 3; }
.dot4 { grid-area: 3 / 1; }
.dot5 { grid-area: 3 / 3; }
.dot6 { grid-area: 2 / 1; } */


@media (max-width:767px){
  .profile_box i {
    padding: 2px;
}
  .profile_name_design{
    font-size: 14px;
  }
  .winner-header {
    font-size: 16px;
}
.winner-name {
  font-size: 13px;
}
.winner-message {
  font-size: 11px;
}
.winneremoji.left {
  width: 35px;
  height: 35px;
  bottom: 11px;
  left: 68px;
}
.winneremoji {
  position: absolute;
  width: 35px;
  height: 35px;
  right: 65px;
  bottom: 14px;
}
}