:root {
  --primary_color: #005556;
  --white_color: #ffffff;
}

.headerContainer {
  background: linear-gradient(90deg, #560c7b, #b595c5 47%, #560c7b);
  max-width: 480px;
  width: 100%;
  position: fixed;
  height: 60px;
  padding: 0 10px;
  top: 0px;
  z-index: 20;
  align-items: center;
  display: flex;
  padding: 0px 10px;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
}

.sideNavIcon {
  overflow: hidden;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  color: #000;
}

.menu_items {
  position: absolute;
  right: 10px;
  align-items: center;
  display: flex;
  top: 0;
  bottom: 0;
}

.sideNavIcon > img {
  margin-bottom: 0;
  width: 40px;
  margin-left: -10px;
}

.navLogo > img {
  width: 70px;
}

.menu_items > .box {
  align-items: baseline;
  display: flex;
  justify-content: space-between;
}

.menu_items > .box {
  border-radius: 15px;
  position: relative;
  text-decoration: none;
}

.moneyIcon_container {
  overflow: hidden;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  margin-right: -5px;
  z-index: 1;
}

.moneyBox_add {
  background-color: #ededed;
  bottom: 0;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 17px;
}
.referBox_add {
  bottom: 0px;
  position: relative;
  right: 0px;
  top: 0px;
  /*height: 95%;*/
  width: 25px;
  border-radius: 50%;
  border: 2px solid #f4bc41;
  color: #f4bc41;
  margin-left: 5px;
  box-shadow: rgb(244 188 65) 0px 0px 0.2rem;
  text-align: center;
}

.moneyIcon_container > img {
  width: 35px;
  margin-left: 4px;
}

.moneyBox_header {
  color: #ffffff;
  font-size: 0.6em;
  font-weight: 500;
  text-transform: uppercase;
}

.moneyBox_text {
  color: var(--white_color);
  font-size: 1em;
  font-weight: 900;
  position: relative;
  bottom: 2px;
}

.login_btn {
  color: #3a427d;
  font-size: 1em;
  font-weight: 900;
  position: relative;
  bottom: 0px;
}

.moneyBox_add > img {
  height: 9px;
  width: 9px;
  margin-left: 6px;
}

.buttoncolor {
  background-image: linear-gradient(
    rgb(61, 128, 197),
    rgb(37, 61, 118)
  ) !important;
  color: black !important;
  border: 2px solid #f4bc41 !important;
  border-radius: 14px;
}
