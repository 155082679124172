.lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 60px;
    height: 60px;
    right: 4%;
  }
  .lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
  }
  .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 13px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #03d25e;
  }
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .pleasewait{
    position: relative;
    font-size: 21px;
  }
  .loaderReturn {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background: linear-gradient(rgba(0,0,0,.5),rgba(0,0,0,0.5)),url('../../../public/Images/banner/ludo_bg.png');
    background-size: contain;
  }
  
/* dice css  */

  /* Dice Container with Perspective */
.dice-container {
  left: -10px;
  perspective: 1000px;
  position: absolute;
  top: 5px;
}

.dice-container.right {
  right: -10px;
  perspective: 1000px;
  position: absolute;
  top: 5px;
  left: auto;
}

/* 3D Dice Styling */
.diceloader {
  width: 100px; /* Set dice width to 100px */
  height: 100px; /* Set dice height to 100px */
  position: relative;
  transform-style: preserve-3d;
  transform: rotateX(30deg) rotateY(45deg);
  animation: rotateDice 3s infinite linear;
}
.dice-faceloader span {
  color: #fff;
  font-size: 13px;
  position: absolute;
  bottom: 0px;
  opacity: 0.7;
  text-transform: uppercase;
  letter-spacing: 3px;
}
/* Dice faceloaders */
.dice-faceloader {
  position: absolute;
  width: 100px; /* Adjust to match dice size */
  height: 100px; /* Adjust to match dice size */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: bold;
  border: 1px solid #333;
  border-radius: 5px;
  background-color: #560c7b;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
}

/* faceloader positioning */
.faceloader1 { transform: rotateY(  0deg) translateZ(50px); }
.faceloader2 { transform: rotateY( 90deg) translateZ(50px); }
.faceloader3 { transform: rotateY(180deg) translateZ(50px); }
.faceloader4 { transform: rotateY(-90deg) translateZ(50px); }
.faceloader5 { transform: rotateX( 90deg) translateZ(50px); }
.faceloader6 { transform: rotateX(-90deg) translateZ(50px); }

/* dotloaderted Design for Dice faceloaders */
.dotloader {
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0 0 2px rgba(255, 223, 0, 0.8);
}

.faceloader1 .dotloader1 {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.faceloader2 .dotloader1 {
  top: 20%;
  left: 20%;
}

.faceloader2 .dotloader2 {
  bottom: 20%;
  right: 20%;
}

.faceloader3 .dotloader1 {
  top: 20%;
  left: 20%;
}

.faceloader3 .dotloader2 {
  top: 20%;
  right: 20%;
}

.faceloader3 .dotloader3 {
  bottom: 20%;
  left: 20%;
}

.faceloader4 .dotloader1 {
  top: 20%;
  left: 20%;
}

.faceloader4 .dotloader2 {
  top: 20%;
  right: 20%;
}

.faceloader4 .dotloader3 {
  bottom: 20%;
  left: 20%;
}

.faceloader4 .dotloader4 {
  bottom: 20%;
  right: 20%;
}

.faceloader5 .dotloader1 {
  top: 20%;
  left: 20%;
}

.faceloader5 .dotloader2 {
  top: 20%;
  right: 20%;
}

.faceloader5 .dotloader3 {
  bottom: 20%;
  left: 20%;
}

.faceloader5 .dotloader4 {
  bottom: 20%;
  right: 20%;
}

.faceloader5 .dotloader5 {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.faceloader6 .dotloader1 {
  top: 20%;
  left: 20%;
}

.faceloader6 .dotloader2 {
  top: 20%;
  right: 20%;
}

.faceloader6 .dotloader3 {
  bottom: 20%;
  left: 20%;
}

.faceloader6 .dotloader4 {
  bottom: 20%;
  right: 20%;
}

.faceloader6 .dotloader5 {
  top: 43%;
  left: 20%;
}

.faceloader6 .dotloader6 {
  top: 43%;
  right: 20%;
}

/* Animation for rotating dice */
@keyframes rotateDice {
  0% { transform: rotateX(0deg) rotateY(0deg); }
  50% { transform: rotateX(180deg) rotateY(180deg); }
  100% { transform: rotateX(360deg) rotateY(360deg); }
}

.logoboxdesign{
  width: 150px;
  height: 150px;
  opacity: 0.5;
}
.logoboxdesign img{
  width: 100%;
  height: 100%;
}

/* dots loader  */
@keyframes dot-keyframes {
  0% {
    opacity: 0.4;
    transform: scale(1, 1);
  }
  50% {
    opacity: 1;
    transform: scale(1.2, 1.2);
  }
  100% {
    opacity: 0.4;
    transform: scale(1, 1);
  }
}

.loading-dots {
  text-align: center;
  display: flex;
  gap: 5px;
  position: relative;
  top: 5px;
  left: 4px;
}
.top-40{
  top:40px;
}
.loading-dots--dot {
  animation: dot-keyframes 1.5s infinite ease-in-out;
  background-color: #fff;
  border-radius: 10px;
  display: inline-block;
  height: 5px;
  width: 5px;
}

.loading-dots--dot:nth-child(2) {
  animation-delay: 0.5s;
}

.loading-dots--dot:nth-child(3) {
  animation-delay: 1s;
}

/* dots loader  */